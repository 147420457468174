.overflow-scroll { // Hide scroll bar on x axis but still allow to scroll
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.ellipsis {
  display: inline;
  white-space: nowrap; /* disable line change */
  overflow: hidden; /* hidden the overflow part */
  text-overflow: ellipsis; /* use ellipsis for the part that exceed width */
}

.ellipsis:hover {
  overflow: visible; /* show entire text */
  display: block;
}
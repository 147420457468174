#bg-img {
  background-color: #232f3e;
  margin-top: 0px;
  height: 310px;
  background-image: url("https://d1.awsstatic.com/training-and-certification/banners/headers-short-enterprise.322371551f17c2bdfd4f1de712ecff7847353006.png");
}

.custom-container {
  margin: 0 auto;
  height: fit-content;
  max-width: 1200px;
  padding: 1rem;
  > h1, > h3 {
    font-family: AmazonEmberLight,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,sans-serif !important;
    color: #fff !important;
    font-weight: 200 !important;
    text-rendering: optimizeLegibility !important;
  }
  > h1 {
    font-size: 4.4rem !important;
    line-height: 1.2 !important;
  }
  > h3 {
    font-size: 2rem !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    line-height: 1.5 !important;
  }
  > button {
    margin-top: 2rem;
    padding: 4px 40px !important;
  }
}

.content-1 {
  p {
    margin-bottom: 15px;
    font-family: AmazonEmberLight,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 1.8rem;
    color: #232f3e;
    line-height: 30px;
  }
}

.content-2 {
  .figure-box {
    border: 1px solid #ddd;
    padding: 15px 20px;
  }
}

.homepage {
  .awsui-context-content-header {
    margin-top: 2rem;
  }
  .button-group {
    margin-top: 2rem;
  }
  .left {
    margin-top: 7rem;
    .bottom-grey {
      padding-bottom: 2rem;
    }
    .logo {
      width: 100%;
    }
  }
  .right {
    a {
      display: block !important;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      line-height: 1;
      border-bottom: 1px solid #d1d5db;
    }
  }
}

.bottom-grey {
  border-bottom: 1px solid #d1d5db;
  padding-bottom: 1rem;
}

.sip-homepage-container {
  .logo {
    width: 100%;
  }
  a {
    width: 100%;
    display: inline-block !important;
  }
}
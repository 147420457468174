.accounts-table {
  th {
    padding: 0 8px 0 0 !important;
    &::before {
      margin: 0 !important;
      max-height: inherit !important;
    }
    max-width: 180px;
    div div {
      white-space: break-spaces !important;
    }
  }
}
#assignedToMe {
  position: relative;
  top: 35px;
}
$margingLeftLoadingSpinner: 36px;

.spinner__loaded-content {
  width: 100%;
}

.spinner-loading-text {
  color: white
}

.spinner-loading-icon {
  margin-left: $margingLeftLoadingSpinner;
  color: white
}

.spinner-loading-content {
  display: flex;
  align-items: center;
}

.spinner-loading-content-left-align {
  justify-content: flex-start;
}

.spinner-loading-content-center-align {
  justify-content: center;
}

.spinner-loading-content-right-align {
  justify-content: flex-end;
}
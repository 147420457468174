.lna-footer {
  .pointer {
    cursor: pointer;
  }
  display: flex;
  margin: 0;
  padding: 7px 16px;
  flex-wrap: wrap;
  justify-content: flex-end;
  box-sizing: border-box;
  .link {
    display: flex;
    gap: 1rem;
    a {
      text-decoration: none;
      & {
        border-right: 1px solid #fff;
        padding-right: 1rem;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .dis {
    margin-left: 1rem;
  }
  .feedback {
    position: absolute;
    left: 6rem;
  }
}

#consoleNavFooter {
  border-top: 1px solid #545b64;
  color: #fff;
  font-family: Amazon Ember,Helvetica Neue,Roboto,Arial,sans-serif;
  font-size: 12px;
  background-color: #232f3e;
  line-height: 14px;
  a {
    color: #fff;
  }
}

.no-margin {
  margin: 0;
}

.loading-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 10rem;
  font-size: larger;
}

#lnaRadioGroup {
  position: relative;
  left: 1rem;
  top: 1rem;
}
#sipRadioGroup {
  position: relative;
  top: -1rem;
  left: 1rem;
}
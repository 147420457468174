.main {
  display: flex;
  align-items: center;
  justify-content:space-between;
  flex-direction: column;
  .error-text {
    color: grey;
    font-size: 2rem;
  }
  .error-content {
    h2 {
      font-size: 8rem;
      color: grey;
      margin: 2rem 0;
      font-weight: 100;
    }
  }
}
